<template>
  <v-app>
    <v-app-bar
      v-if="$route.path !== '/login'"
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Counters Logo"
          class="shrink mr-2"
          contain
          src="logo.png"
          transition="scale-transition"
          width="50"
        />

        <h2>Counters</h2>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>


    <v-navigation-drawer
      v-if="$route.path !== '/login'"
      permanent
      left
      app
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in menu"
          :key="item.title"
          :to="item.href"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',

  data: () => ({
    user: { 
      firstName: '',
      lastName: '',
      email: ''
    },
    menu: [
      { title: 'Dashboard', icon: '', href: '/' },
      { title: 'Customers', icon: '', href: 'customers' },
      { title: 'Devices', icon: '', href: 'devices' },
      { title: 'All Counters', icon: '', href: 'counters' },
      { title: 'Reports', icon: '', href: 'reports' },
    ],
  }),
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
  
      axios.post('https://counters.digiworx.nl/api/user/getInfo')

      .then((response) => {
        // handle success
        this.user = response.data
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
  }
};
</script>
