<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          tile
        >
          <v-card-title>No counters received</v-card-title>
          <v-simple-table
            fixed-header
            height="300px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Customer
                  </th>
                  <th class="text-left">
                    Brand/Model
                  </th>
                  <th class="text-left">
                    Serial
                  </th>
                  <th class="text-left">
                    Last received
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in noCountersList"
                  :key="item.serial"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.brand }} {{ item.model }}</td>
                  <td>{{ item.serial }}</td>
                  <td>{{ item.last_date }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  
  export default {
    name: 'HomeView',

    data: () => ({
      noCountersList: [
      ]
    }),
    mounted () {
      this.getData()
    },
    methods: {
      getData () {
    
        axios.post('https://counters.digiworx.nl/api/devices/getNoCountersList')

        .then((response) => {
          // handle success
          this.noCountersList = response.data
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
      },
    },
  }
</script>