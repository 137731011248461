import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: guardMyroute,
    component: HomeView
  },
  {
    path: '/customers',
    name: 'customers',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomersView.vue')
  },
  {
    path: '/counters',
    name: 'counters',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CountersView.vue')
  },
  {
    path: '/devices',
    name: 'devices',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DevicesView.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportsView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function guardMyroute(to, from, next)
{
  axios
    .get('https://counters.digiworx.nl/api/auth')
    .then(response => {
      if(response.data.status === '1') {
        next();
        console.log(response.data.status)
      }
      else {
        next('/login');
      }
    })
    .catch(error => {
      console.log(error)
    })
}
export default router
